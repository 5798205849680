@import '~ngx-bar-rating/themes/br-stars-theme';
@import '~ngx-bar-rating/themes/br-bootstrap-theme';
@import '~ngx-bar-rating/themes/br-fontawesome-o-theme';
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

:host {
  font-family: "Roboto", sans-serif;
}
a{
  cursor: pointer;
}
html,
body {
  margin: 0;
  min-height: 100vh;
  height: 100%;
  max-width: 100vw;
  font-family: "Roboto", sans-serif;
  color: black;
  background: white;
}

.btn-ripple {
  display: inline-block;
  position: relative;
  overflow: hidden;
  transition: all linear .5s;
}

.space-between {
  justify-content: space-between;
  display: flex;
}

.btn-ripple::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 25%;
  height: 100%;
  width: 50%;
  background-color: #fff;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  transition: all linear .5s;
  transform: scale(5, 5);
}

.btn-ripple:active::after {
  padding: 0;
  margin: 0;
  opacity: .2;
  transition: 0s;
  transform: scale(0, 0);
}

.btn-coffee {
  outline: none;
  border: none;
  cursor: pointer;
  background: #0F9847;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 23px;
  opacity: 1;
  color: #FFFFFF;
  padding: 8px 15px;
}

.color-padrao-app {
  color: #21C1B1;
}

.btn-red {
  outline: none;
  border: none;
  cursor: pointer;
  background: #b9152b;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 23px;
  opacity: 1;
  color: #FFFFFF;
  padding: 8px 15px;
}

.btn-coffee:focus {
  outline: none;
}

.btn-coffee:disabled {
  opacity: .5;
  cursor: default;
}

.pointer {
  cursor: pointer;
}

.underline:hover {
  text-decoration: underline;
}

@media (min-width: 768px){
  .container, .container-md, .container-sm {
    max-width: 100%!important;
  }
}

@media (min-width: 992px){
  .container, .container-lg, .container-xl {
    max-width: 960px!important;
  }
}

@media (min-width: 1200px){
  .container, .container-xl {
    max-width: 1140px!important;
  }
}

.ft-8 {
  font-size: 8px;
}

.ft-10 {
  font-size: 10px;
}

.ft-12 {
  font-size: 12px;
}

.ft-14 {
  font-size: 14px;
}

.ft-16 {
  font-size: 16px;
}

.ft-18 {
  font-size: 18px;
}

.ft-20 {
  font-size: 20px;
}

.ft-22 {
  font-size: 22px;
}

.ft-24 {
  font-size: 24px;
}

.ft-26 {
  font-size: 26px;
}

.ft-28 {
  font-size: 28px;
}

.ft-30 {
  font-size: 30px;
}

.form-group {
  label {
    font-size: 12px;
    margin-bottom: 5px;
  }
}

.btn-group-lg>.btn, .btn-lg {
  padding: .2rem 4rem !important;
  font-size: 15px !important;
  line-height: 1.5 !important;
  border-radius: .3rem !important;
  font-weight: 500 !important;
}

.titulo-head {
  font-size: 12px !important
}
.form-control {
  border: 1px solid #D1D1D1;
  border-radius: 20px;
}

.form-control::placeholder {
  color: black;
  font-size: 10px;
}

.box {
  background: #FFFFFF;
  box-shadow: 0 3px 6px #00000029;
  border-radius: 20px;
}

.top-bar {
  height: 50px;
  display: flex;
  align-items: center;

  .top-bar-content {
    display: flex;
    align-items: center;
    * {
      margin-bottom: 0;
    }

    svg {
      height: 18px;
      width: 18px;
    }

    .simple-text {
      font-weight: bold;
      font-size: 14px;
      color: black;
    }

    
    .header-text {
      font-size: 12px;
      color: #B2B2B2;
    }

    .special-text {
      color: black;
      font-size: 12px;
    }
    b {
      font-size: 14px;
    }
  }
}

.profile-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

small {
  font-family: "Roboto", sans-serif;
}

.text-right {
  text-align: right;
}

.text-end {
  text-align: end;
}

.text-center {
  text-align: center;
}

.bold {
  font-weight: 700;
}

.color-orange {
  color: #EF7D20 !important;
}

.cor-padrão {
  background-color: red;
}

.material-icons {
  font-size: 20px;
}

@media screen and (max-width: 1080px){
  .top-bar {
    .top-bar-content {
      margin-left: 32px;
      svg {
        display: none;
      }
      .simple-text {
        font-size: 14px;
      }
    }
  }
}

strong {
  color: #000 !important;
  font-family: "Roboto","Medium";
  font-size: 14px;
  font-weight: 500 !important;

}

//table
.table thead th {
  font-weight: 200 !important;
}

.table td, .table th {
  padding: .6rem !important;
}

//button 

.btn-warning {
    color: #FFFF !important;
    background-color: red  !important;
    border-color:red  !important;
    font-weight: 700 !important;
}

.btn-secondary {
  color: #fff !important;
  background-color: #707070 !important;
  border-color: #707070 !important;
}

//Alert
.alert-warning {
  color: #ff8400 !important;
  background-color: #facca9 !important;
  border-color: #D8D8D8 !important;
  font-weight: 400 !important;

}

.fa-ellipsis-h {
  color: #000;
}

.h5, h5 {
  font-size: 1.1rem !important;
}